<div class="row">
    <div class="container mt-20 d-flex justify-content-center">
        <mat-form-field appearance="fill">
            <mat-label>Select an option</mat-label>
            <mat-select [(value)]="selected">
              <mat-option [value]=source.id *ngFor="let source of sources">{{source.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-button (click)="onSourceChange()">Submit</button>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mt-10" *ngFor="let article of news?.articles">
        <mat-card>
            <img mat-card-image *ngIf="article.urlToImage" [src]="article.urlToImage" alt="">
        
            <mat-card-header>
                <mat-card-title>{{article.title}}</mat-card-title>
            </mat-card-header>
        
            <mat-card-content>
                <p>
                    {{article.content }}
                </p>
        
            </mat-card-content>
        
            
        </mat-card>
        </div>
</div>
