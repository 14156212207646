import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  constructor(private snackBar:MatSnackBar) { }
  articles:any;
  ngOnInit(): void {
    this.getFavorites();
  }
getFavorites() {
  const val= localStorage.getItem('items');
  if (val!=null) {
this.articles= JSON.parse(val);
  }
}
onUnfavorite(article:any) {
  const index = this.articles.indexOf(article);
  this.articles.splice(index, 1);
  localStorage.setItem('items', JSON.stringify(this.articles));
  this.snackBar.open('Favorite removed', 'ok', {duration:3000});
}
}
