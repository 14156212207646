<div class="row">
<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mt-10" *ngFor="let article of articles">
    <mat-card>
        <img mat-card-image *ngIf="article.urlToImage" [src]="article.urlToImage" alt="">
    
        <mat-card-header>
            <mat-card-title>{{article.title}}</mat-card-title>
        </mat-card-header>
    
        <mat-card-content>
            <p>
                {{article.content }}
            </p>
    
        </mat-card-content>
    
        <mat-card-actions>
            <button mat-button (click)="onUnfavorite(article)">Unfavorite</button>
            <a mat-button href="{{article.url}}" target="_blank">Read more</a>
        </mat-card-actions>
    </mat-card>
    </div>
</div>
