
  <mat-toolbar color="primary">
    <span class="spacer">Angular News</span>
    <div>
    <button mat-button [routerLink]="['/']">Home</button>
    <button mat-button [routerLink]="['/headlines']">Headlines</button>
    <button mat-button [routerLink]="['/sources']">Sources</button>
    <button mat-button [routerLink]="['/favorites']">Favorites</button>
  </div>
  </mat-toolbar>


  <div class="container">
<router-outlet></router-outlet>
</div>