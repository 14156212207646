import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
news : any;
newsSubscription:any;
length:any;
pageSize=8;
page=1;


  constructor(private newsService:NewsService, private snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.getData();
  }
    getData() {
    this.newsSubscription = this.newsService.getData(`top-headlines?country=se&pageSize=${this.pageSize}&page=${this.page}`).subscribe(data=> {
        this.news=data;
    this.length=this.news.totalResults;
      });
    }

    ngOnDestroy() {
      this.newsSubscription.unsubscribe();
    }
  
    onFavorite(article:any) {
      console.log(article);

      let items = [];
      const val = localStorage.getItem('items');

      if (val!=null) {
        items=JSON.parse(val);
      }
      items.push(article);
      localStorage.setItem('items', JSON.stringify(items));
      this.snackBar.open('Favorite added', 'ok', {duration:3000});
    }

    onPageChange(event:any) {
      console.log(event);
      this.newsSubscription = this.newsService.getData(`top-headlines?country=se&pageSize=${this.pageSize}&page=${event.pageIndex + 1}`).subscribe(data=> {
        this.news=data;
    this.length=this.news.totalResults;
      });
    }

}
