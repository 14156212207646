import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'app-headlines',
  templateUrl: './headlines.component.html',
  styleUrls: ['./headlines.component.scss']
})
export class HeadlinesComponent implements OnInit, OnDestroy {
  categories = [
    'World',
    'Sweden',
    'Business',
    'Technology',
    'Entertainment',
    'Sports',
    'Science' ];

  news:any;
  newsSubscription:any;


  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.getCategoryData(this.categories[0]);
  }

  onGetCategoryData(category:any) {
    console.log(category);
    this.getCategoryData(category);
  }

  getCategoryData(category:any) {
    this.newsSubscription = this.newsService.getData(`everything?q=${category.toLowerCase()}`).subscribe(data =>{this.news = data;})
  }

  ngOnDestroy() {
    this.newsSubscription.unsubscribe();
  }
}
